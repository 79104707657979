import Vue from 'vue'
import VueRouter from 'vue-router'
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from '../views/auth/Callback.vue'
import Unauthorized from '../views/auth/Unauthorized.vue'
import DataService from "../services/DataService";
Vue.use(VueRouter)
const routes = [
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: "/Project/Index",
        name: "ProjectList",
        component: () => import('../views/Project/Project/Project.vue'),
        meta: {
            title: 'Project',
            checkPrivilege: false,
            breadcrumb: [
                { name: "Project" }
            ]
        }
    },
    {
        path: "/Project/ProjectOverview/:projectId",
        name: "ProjectDetail",
        component: () => import('../views/Project/ProjectDetail.vue'),
        meta: {
            title: 'ProjectDetail',
            checkPrivilege: false,
            breadcrumb: [
                { name: "Manage Project" }
            ]
        }
    },
    {
        path: "/Project/Task/:projectId",
        name: "ProjectTask",
        component: () => import('../views/Project/Project/ProjectDetailLeftAllTabs/ProjectTaskListing.vue'),
        meta: {
            title: 'ProjectTask',
            checkPrivilege: false,
            breadcrumb: [
                { name: "Project Task" }
            ]
        }
    },
    {
        path: "/Project/Team/:projectId",
        name: "ProjectTeam",
        component: () => import('../views/Project/Project/ProjectDetailLeftAllTabs/ProjectTeamListing.vue'),
        meta: {
            title: 'ProjectTeam',
            checkPrivilege: false,
            breadcrumb: [
                { name: "Project Team" }
            ]
        }
    },
    {
        path: "/Quotation/Create/:reqfrom/:quotationTypeCode/:id",
        name: "Quotation",
        component: () => import('../views/Project/Project/ManageQuotation.vue'),
        meta: {
            title: 'Add Quotes',
            checkPrivilege: false,
            breadcrumb: [
                { name: "Project" },
                { name: 'Add a Quote' }
            ]
        }
    },
    {
        path: "/Task/Index",
        name: "TaskList",
        component: () => import('../views/Project/Task/TaskList.vue'),
        meta: {
            title: 'Task',
            checkPrivilege: false,
            breadcrumb: [
                { name: "Project" }
            ]
        }
    },
    {
        path: "/ProjectRequest/Index",
        name: "ProjectRequestList",
        component: () => import('../views/Project/ProjectRequest/ProjectRequest.vue'),
        meta: {
            title: 'Project Request',
            checkPrivilege: false,
            breadcrumb: [
                { name: "Project Request" }
            ]
        }
    },
    {
        path: "/Quotation/DisplayQuotation/:QuotationId/:Code",
        name: "DisplayQuotation",
        component: () => import('../views/Project/ProjectRequest/DisplayQuotation.vue'),
        meta: {
            title: 'Preview Quotation',
            checkPrivilege: false,
            breadcrumb: [
                { name: "Client Bill" }, { name: "Quotes" }, { name: "View" }
            ]
        }
    },
    {
        path: "/ProjectRequest/Details/:QuotationId",
        name: "ProjectRequestDetails",
        component: () => import('../views/Project/ProjectRequest/ProjectRequestDetails.vue'),
        meta: {
            title: 'Project Request',
            checkPrivilege: false,
            breadcrumb: [
                { name: "Project Request" }
            ]
        }
    },
    {
        path: "/Project/ToDoList",
        name: "ProjectToDoList",
        component: () => import('../views/Project/WorkLoad/ToDoList.vue'),
        meta: {
            title: 'To Do List',
            checkPrivilege: false,
            breadcrumb: [
                { name: "To Do List" }
            ]
        }
    },
    {
        path: "/Project/ToDoList/:projectId",
        name: "ToDoList",
        component: () => import('../views/Project/WorkLoad/ToDoList.vue'),
        meta: {
            title: "To Do List",
            breadcrumb: [{ name: "To Do List" }],
        },
    },
    {
        path: "/Project/GanttChart/:projectId/:project_name",
        name: "GanttChart",
        component: () => import('../views/Project/GanttChart/GanttChart.vue'),
        meta: {
            title: "Gantt-Chart",
            breadcrumb: [{ name: "Gantt Chart" }],
        },
    },
    {
        path: "/ResourceScheduling/Create",
        name: "ResourceSchedulingList",
        component: () => import('../views/Project/ResourceScheduling/Create.vue'),
        meta: {
          title: "Resource Scheduling",
          breadcrumb: [{ name: "ResourceScheduling" }],
        },
    },
    {
        path: "/GlobalTask/Index",
        name: "GlobalTask",
        component: () => import('../views/Project/GlobalTask/GlobalTaskList.vue'),
        meta: {
          title: "Manage Global Task",
          breadcrumb: [{ name: "Project" },{ name: "Global Task" }],
        },
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
    // debugger;
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'block';
    if (to.path == '/unauthorized') {
        next()
    }
    else {
        const ustore = useloggedUserInfo();
        let app = ustore.GetUserInfo;
        if (app) {
            let isCheckPrivilege = true;
            if (to.meta.hasOwnProperty('checkPrivilege')) {
                isCheckPrivilege = to.meta.checkPrivilege;
            }
            if (isCheckPrivilege) {
                let controllerName = "", actionName = "";
                if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                    controllerName = to.meta.controllerName;
                    actionName = to.meta.actionName;
                }
                else {
                    let pathArray = to.path.split("/");
                    if (pathArray.length > 2) {
                        controllerName = pathArray[1];
                        actionName = pathArray[2];
                    } else {
                        controllerName = pathArray[0];
                        actionName = pathArray[1];
                    }
                }
                if (controllerName != "" && actionName != "") {
                    DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                        if (value.data)
                            next()
                        else
                            //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                            next('/unauthorized');
                    },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    next()
                }
            } else {
                next()
            }
        }
        else {
            //authService.authenticate(to.path).then(() => {
            console.log('authenticating a protected url:' + to.path);
            next();
            // });
        }
    }
});
export default router

