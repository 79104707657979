import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  GetContextMenu(params) {
  return http.instanceCommon.get("/Common/GetContextMenu?"+params);
  }
  async CheckPrivilege(params){
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  async GetDDLData(params) { 
    return await http.instance.get("/Common/GetDDLData?" + params);
  }
  async GetCompanyLocation(params){   
    return await http.instance.get("/HrReports/GetCompanyLocation?" + params);
  }
  async GetUserListByLocationId(params){   
    return await http.instance.get("/HrReports/GetUserListLocationId?" + params);
  }
  async GetConfigurationValueForCompanyByKey(param) {
    return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?" + param);
  }
  async GetHideContextMenuConfig() {
    return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
  }
  async GetLeaveType(params){   
    return await http.instance.get("/HrReports/GetLeaveType?" + params);
  }
  async GetUserList(params) {  
    return await http.instance.get("/HrReports/GetUserList?" + params);
  }
  async GetProjectList(params) {  
    return await http.instance.get("/HrReports/BindProjectDropdownByCompanyIdAndUserId?" + params);
  }
  async GetApprover(params) {  
    return await http.instance.get("/HrReports/GetApproverByApprovalType?" + params);
  }
  async GetApprovalGroup(params) {  
    return await http.instance.get("/HrReports/LoadApprovalGroup?" + params);
  }
  async GetStatusList(params) {  
    return await http.instance.get("/HrReports/GetStatus?" + params);
  }
  async GetEmployeeByCompanyId(params){     
    return await http.instance.get("/HrReports/GetLicencedUsers?" + params);
  }
  async ReportMain(params) {
    return await http.instance.post("/Report/ReportMain", { postString: params });
  }
  async GetYears(params){     
    return await http.instance.get("/HrReports/GetCompanySessionYear?" + params);
  }
  async GetLocationByuserId(params) {
    return await http.instance.get("/AssetReport/GetLocationByuserId?" + params
    );
  }
  async AssignGetAssetType(params) {   
    return await http.instance.get("/AssetReport/GetAssetType?" + params);
  }
  async GetIssuedUser() {
    return http.instance.get("/AssetReport/LoadIssuedUser?");
  }
  GetAssetCatalogueType(param) {
    return http.instance.get("AssetReport/GetAssetCatalogueType?" + param);
  }
  GetRelatedCatalogues(param) {
    return http.instance.get("AssetReport/GetRelatedCatalogues?" + param);
  }
  async GetUserStatusitemlog() {
    return http.instance.get("AssetReport/GetAllAssetOperationCodes?");
  }
  async GetTagsByModuleId(param) {
    return await http.instance.get("AssetReport/GetTagsByModuleId?" + param);
  }
  GetUserLicenseModulesReport(){
    return http.instance.get("CRMReport/GetUserLicenseModulesReport");
  }
  GetCompanyReportDDLTypes(param){
    return http.instance.get("CRMReport/GetCompanyReportDDLTypes?"+param);
  }
  GetCompanyLeadOwners(param)
  {
    return http.instance.get("CRMReport/GetCompanyLeadOwners?"+param);
  }
  GetCompanyClientsByCompanyId()
  {
    return http.instance.get("CRMReport/GetCompanyClientsByCompanyId");
  }
  GetDDLStages()
  {
    return http.instance.get("CRMReport/GetDDLStages");
  }
  GetCompanyDealOwners(param)
  {
    return http.instance.get("CRMReport/GetCompanyDealOwners?"+param);
  }
  GetProjectByCompanyIdAndUserId(){  
    return http.instance.get("ExpenseReport/ProjectDropdownByCompanyIdAndUserId");
  }
  GetAllCategoriesByCompanyId(){
      ;
    return http.instance.get("ExpenseReport/GetAllCategoriesByCompanyId");
  }
  GetUsersByProjectIds(params) {
    return  http.instance.get("ExpenseReport/GetUsersByProjectIds?" + params);
  }
  // GetAllCategoriesByCompanyId(){
  //   return http.instanceExpense.get("/GetAllCategoriesByCompanyId");
  // }
  GettTripListingddl() {   
    return  http.instance.get("ExpenseReport/GetTripName/");
  }
  ExpenseGetUserListing(params) {         
    return  http.instance.get("ExpenseReport/GetUsersByCompanyId/", { params });
  }
  
async GetClientList(params) {
   return await http.instance.get("/TicketingReport/GetCompanyClient?" + params);
}
async GetPrioritybyCompanyId(params) {
  return await http.instance.get("/TicketingReport/GetPrioritybyCompanyId?" + params);
}
async GetDepartmentListbyCompanyForClient() {
  return await http.instance.get("/TicketingReport/GetDepartmentListbyCompanyForClient");
}
async FetchTicketStatusList(params) {
  return await http.instance.get("/TicketingReport/GetTicketStatusMapping?" + params); 
}
async GetTicketStatus() {
  return await http.instance.get("/TicketingReport/GetTicketStatus");
}
async GetDDLCategoryForTicket(params) {
  return await http.instance.get("/TicketingReport/GetDDLCategoryForTicket?" + params);
}
async GetProductsList() {
  return await http.instance.get("/TicketingReport/GetProductsList");
}
async GetDDLChannelByCompanyId(params) {
  return await http.instance.get("/TicketingReport/GetDDLChannelByCompanyId?" + params);
}
async GetApprovalTypes(param) {
  return await http.instance.get("/ApprovalReport/GetApprovalTypes?"+ param );
}
async GetApprovalChainsByCompanyId() {
  return await http.instance.get("/ApprovalReport/GetApprovalChainsByCompanyId?");
}
async LoadUsersByCompanyId(param) {
  return await http.instanceApproval.get("/ApprovalReport/LoadUsersByCompanyId?"+ param);
}
async GetLeaveTypes(params){      
  return await http.instance.get("/BIReport/GetLeaveTypes?" + params);
}
async GetUsers(params){   
  return await http.instance.get("/BIReport/LoadUser?" + params);
}
async GetChannel(){  
  return await http.instance.get("/BIReport/ReportChannel");
}
async GetDepartments(){   
  return await http.instance.get("/BIReport/GetDepartmentByUserId");
}
async GetClientListByProjectId (params){   
  return await http.instance.get("/BIReport/GetClientListByProjectId?" + params);
}
async GetTaskByProject (params){   
  return await http.instance.get("/BIReport/ListProjectsandTasks?" + params);
}
async GetApplication (params){
  return await http.instance.get("/BIReport/GetApplicationByProjectType?" + params);
}
async GetGetDeviceOrOS (params){
  return await http.instance.get("/BIReport/GetDeviceOrOS?" + params);
}

async GetScreenShotListing (params){   
  return await http.instance.get("/Common/GetScreenShotListing?" + params);
}
async GetCategoryListing (params){   
  return await http.instance.get("/Common/GetCategoryListing?" + params);
}
async GetUserListByManagerId(params){   
  return await http.instance.get("/HrReports/GetUserListByManagerId?" + params);
}
async GetPost(){   
  return await http.instance.get("/Common/GetPosts");
}
async GetCompanySkills(){   
  return await http.instance.get("/Common/GetCompanySkills");
}
async GetTrainer(){   
  return await http.instance.get("/Common/GetTrainer");
}
async GetTicketStatusActiveClosedReport(){   
  return await http.instance.get("/Common/GetTicketStatusActiveClosedReport");
}
async GetResearcher(){   
  return await http.instance.get("/Common/GetResearcher");
}
async GetWorkOrderStatusActiveCompleteReport(){   
  return await http.instance.get("/Common/GetWorkOrderStatusActiveCompleteReport");
}
async GetUsersofAllServices(){   
  return await http.instance.get("/Common/GetUsersofAllServices");
}
async GetAllAppointmentServices(){   
  return await http.instance.get("/Common/GetAllAppointmentServices");
}
async GetProectWithBU(){   
  return await http.instance.get("/Common/GetProectWithBU");
}
}
export default new DataService();
