import axios from "axios";
import { useloggedUserInfo } from "./stores/UserInfo";
// Our APIGATEWAY URL
const baseDomain = process.env.VUE_APP_API_URL;
const baseURL = `${baseDomain}`;
const baseCommonURL = process.env.VUE_APP_COMMON_API;
const baseURLCommon = `${baseCommonURL}`;
const baseDocStorage = process.env.VUE_APP_DOCSTORAGE_API;
const baseDocStorageUrl = `${baseDocStorage}`
const baseDomainTimer = process.env.VUE_APP_TIMER_API_URL;
const baseURLTimer = `${baseDomainTimer}`;


const instanceTimer = axios.create({
    baseURL: baseURLTimer,
});
const instance = axios.create({
    baseURL,
});
const instanceCommon = axios.create({
    baseURL: baseURLCommon,
});
const instancebaseDocStorage = axios.create({
    baseURL: baseDocStorageUrl
});
instance.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = config.url == '/ImportProjectPost' ? "multipart/form-data" : "application/json;charset=UTF-8";
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
//Use for if accept modal in backend
instanceTimer.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json";
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceCommon.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
instancebaseDocStorage.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default { instance, instanceCommon, instancebaseDocStorage,instanceTimer };
