import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  CompanyHolidayMultipleLocation(params) {
    return http.instance.get("/CompanyHoliday/GetMultiLocationByCompanyId?" + params);
  }

  AddCompanyChildLocation(params) {
    return http.instance.get("/Location/AddChildLocationById?" + params);
  }

  GetClientByCompanyId(params) {
    return http.instance.get("/CompanyHoliday/GetClientByCompanyId?" + params);
  }

  CurrencyManagementCurrencyDropdown(params) {
    return http.instance.get("/CurrencyManagement/GetCountrywithCurrencies?" + params);
  }
  AnnouncementMessageListing(params) {
    return http.instance.get("/AnnouncementMessage/GetList?" + params);
  }
  BreakListing(params) {
    return http.instance.get("/Break/GetList?" + params);
  }
  CannedReplyListing(params) {
    return http.instance.get("/CannedReply/GetList?" + params);
  }
  CategoryListing(params) {
    return http.instance.get("/Category/GetList?" + params);
  }
  ChannelListing(params) {
    return http.instance.get("/Channel/GetList?" + params);
  }
  CompanyHolidayListing(params) {
    return http.instance.get("/CompanyHoliday/GetList?" + params);
  }
  CompanyNotificationListing(params) {
    return http.instance.get("/CompanyNotification/GetList?" + params);
  }
  ContactTypeListing(params) {
    return http.instance.get("/ContactType/GetList?" + params);
  }
  CurrencyManagementListing(params) {
    return http.instance.get("/CurrencyManagement/GetList?" + params);
  }
  async GetBaseCurrency(param) {
    return await http.instance.get("/Common/GetConfigurationValueByKey?" + param);
  }
  DepartmentListing(params) {
    return http.instance.get("/Department/GetList?" + params);
  }
  DesignationListing(params) {
    return http.instance.get("/Designation/GetList?" + params);
  }
  async GetUserCompanyType(param) {
    return await http.instanceCommon.get("/Common/GetUserCompanyType?" + param);
  }
  EmailTemplateListing(params) {
    return http.instance.get("/EmailTemplate/GetList?" + params);
  }
  FeedbackListing(params) {
    return http.instance.get("/Feedback/GetList?" + params);
  }
  GreetingListing(params) {
    return http.instance.get("/Greeting/GetList?" + params);
  }
  ImmigrationListing(params) {
    return http.instance.get("/Immigration/GetList?" + params);
  }
  IndustryTypeListing(params) {
    return http.instance.get("/IndustryType/GetList?" + params);
  }
  LocationListing(params) {
    return http.instance.get("/Location/GetList?" + params);
  }
  LoginAuthorizationListing(params) {
    return http.instance.get("/LoginAuthorization/GetList?" + params);
  }
  PaymentGatewayListing(params) {
    return http.instance.get("/PaymentGateway/GetList?" + params);
  }
  ProvisionalRightListing(params) {
    return http.instance.get("/ProvisionalRight/GetList?" + params);
  }
  ReviewTemplateListing(params) {
    return http.instance.get("/ReviewTemplate/GetList?" + params);
  }
  ServiceListing(params) {
    return http.instance.get("/Service/GetList?" + params);
  }
  ShiftListing(params) {
    return http.instance.get("/Shift/GetList?" + params);
  }
  SkillListing(params) {
    return http.instance.get("/Skill/GetList?" + params);
  }
  StageListing(params) {
    return http.instance.get("/Stage/GetList?" + params);
  }
  StatusListing(params) {
    return http.instance.get("/Status/GetList?" + params);
  }
  StorageListing(params) {
    return http.instance.get("/Storage/GetList?" + params);
  }
  UserTitleListing(params) {
    return http.instance.get("/UserTitle/GetList?" + params);
  }
  ReviewTemplateQuestionsListing(params) {
    return http.instance.get("/ReviewTemplate/GetReviewQuestionList?" + params);
  }
  async GetDynamicForm(params) {
    return await http.instanceCommon.get("/FormLayout/GetForm?" + params);
  }
  async SaveDynamicForm(params) {
    return http.instanceCommon.post("/FormLayout/SaveDynamicForm", { postString: params })
  }

  async SavePrivileges(params) {
    return http.instanceCommon.post("/Common/SavePrivileges/", { postString: params })
  }

  // async DepartmentAdd(params,url){
  //   return await http.instance.post(url,{ postString: params} );
  // }

  async DepartmentAdd(params, url) {
    return await http.instanceModals.post(url, { postString: params });
  }

  async DeleteCompanySetup(params, url) {
    return await http.instance.post('/department/CommonDelete', { postString: params });
  }
  GetContextMenu(params) {
    return http.instanceCommon.get("/Common/GetContextMenu?" + params);
  }

  GetRoles(params) {
    //debugger
    return http.instanceCommon.get("/Common/GetRoles?" + params);
  }

  GetStatusListData(params) {
    //debugger
    return http.instanceCommon.get("/Common/GetStatusList?" + params);
  }

  GetRoleDetails(params) {
    //debugger
    return http.instanceCommon.get("/Common/GetRoleDetails?" + params);
  }

  GetUserTypes(params) {
    //debugger
    return http.instanceCommon.get("/Wizard/GetUserTypes?" + params);
  }

  UserListPopUp(params) {
    //debugger
    return http.instanceCommon.get("/Common/UserListPopUp?" + params);
  }

  CheckDuplicate(params) {
    //debugger
    return http.instanceCommon.get("/Common/CheckDuplicate?" + params);
  }

  CopyRole(params) {
    //debugger
    return http.instanceCommon.get("/Common/CopyRole?" + params);
  }

  UpdateRoleStatus(params) {
    //debugger
    return http.instanceCommon.get("/Common/UpdateRoleStatus?" + params);
  }

  GetModuleList(params) {
    //debugger
    return http.instanceCommon.get("/Common/GetModuleList?" + params);
  }

  GetPrivilegeList(params) {
    //debugger
    return http.instanceCommon.get("/Common/GetPrivilegeList?" + params);
  }

  CommonCommonDelete(params) {
    return http.instanceCommon.post("/Common/CommonDelete", { postString: params });
  }

  async CheckPrivilege(params) {
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  async GetDDLData(params) {
    return await http.instanceCommon.get("/Common/GetDDLData?" + params);
  }
  async GetScreenShotListing(params) {
    return await http.instance.get("/GetScreenShotListing?" + params);
  }

  async GetTimeTrackingHistoryListing(params) {
    return await http.instance.get("/GetWebApplicationHistoryListing?" + params);
  }

  async GetDropdownlistApplicationNotAssigned(params) {
    return await http.instance.get("/GetDropdownlistApplicationNotAssigned?" + params);
  }
  async GetDepartmentList() {
    return await http.instanceCommon.get("/Common/GetDepartmentList?");
  }
  async SaveAssignDepartment(params) {
    return await http.instance.post("/SaveAssignDepartment", { postString: params });
  }
  async Delete(param) {
    return await http.instanceCommon.post("/Common/Delete?" + param);
  }
  // async GetAssignDepartmentByApplicationId(params) {
  //   return await http.instance.get("/GetAssignDepartmentByApplicationId?" + params);
  // }
  // async GetCategoryListing(params) {
  //   return await http.instance.get("/GetCategoryListing?" + params);
  // }
  // async ManageCategory(params) {
  //   return await http.instance.post("/ManageCategory", { postString: params });
  // }
  async EditBulkTimeTrackEntry(params) {
    return await http.instance.post("/EditBulkTimeTrackEntry", { postString: params });
  }
  // async GetCategoryById(params) {
  //   return await http.instance.get("/GetCategoryById?" + params);
  // }

  async TimerDashboard(params) {
    return await http.instance.get("/TimerDashboard?" + params);
  }

  async getArrivalTimeDashboard(params) {
    return await http.instance.get("/AverageTimeBarDashboard?" + params)

  }
  async GetTimerProjectsByUserID() {
    return await http.instance.get("/GetTimerProjectsByUserID")
  }

  async GetAllApps(params) {
    return await http.instance.get("/GetAllApps?" + params);
  }
  async GetMostUsedApps(params) {
    return await http.instance.get("/GetMostUsedApps?" + params);
  }
  async GetUsedAppTimeDurationByCategories(params) {
    return await http.instance.get("/GetUsedAppTimeDurationByCategories?" + params);
  }
  async GetTimerListing(params) {
    return await http.instance.get("/GetTimerListing?" + params);
  }
  async LoadTask(params) {
    return await http.instance.get("/LoadTask?" + params);
  }
  async GetCompanyTaglist(params) {
    return await http.instance.get("/GetCompanyTaglist?" + params);
  }
  async GetProductiveEmployees(params) {
    return await http.instance.get("/GetProductiveEmployees?" + params);
  }
  async GetIncompleteEntry() {
    return await http.instance.get("/GetIncompleteEntry");
  }
  // async GetIncompleteEntry(){
  //   return await http.instance.get("/GetIncompleteEntry");
  // }
  async SaveProjectInfo(info) {
    return await http.instanceModals.post("/SaveTimeEntry", info);
  }
  async GetDepartmentwithUsers(params) {
    return await http.instance.get("/GetDepartmentWithUsers?" + params);
  }
  async GetTimerListingByTrackId(info) {
    return await http.instance.get("/GetTimerListingByTrackId" + info);
  }
  async LoadTasks(id) {
    return await http.instance.get("/LoadTask?projectId=" + id);
  }
  async setAction(params) {
    return await http.instance.post("/UpdateOrDeleteEntries?" + params);
  }
  async GetBreakTimeList() {
    return await http.instance.get("/GetAllShiftBreakListing");
  }
  async getUserByProjectIdAndTaskId(params) {
    return await http.instance.get("/GetUserListByProjectIdAndTaskId?" + params);
  }
  async SubmitBulkTimeEntry(params) {
    return await http.instance.post("/BulkTimeEntry", { postString: params });
  }
  // async SubmitBulkTimeEntry(params){
  //   debugger;
  //   return await http.instance.post("/BulkTimeEntry?" + params );
  // }
  async AddManually(data) {
    return await http.instanceModals.post("/AddManually", data);
  }
  async DeleteByTrackId(data) {
    return await http.instance.post("/DeleteByTrackId?" + data);
  }
  async SaveTagPopUp(data) {
    return await http.instanceModals.post("/SaveTagPopUp", data);
  }

  // async GetTimeTrackingHistoryListing(params){
  //   return await http.instance.get("/GetWebApplicationHistoryListing?" + params);
  // }
  async GetUserNameandshift(params) {
    return await http.instance.get("/GetShiftDetailsByUsersId?" + params);
  }
  async GetAllData(params) {
    return await http.instance.get("/WorkDiary?" + params);
  }
  GetClockedHours(params) {
    return http.instance.get("/GetClockedHours?" + params);
  }
  GetApplicationTracking(params) {
    return http.instance.get("/GetApplicationTracking?" + params);
  }
  Deletescreenshots(params) {
    return http.instance.delete("/Deletescreenshots?" + params);
  }
  FlagScreenShots(params) {
    return http.instance.get("/FlagScreenShots?" + params);
  }
  PostComment(params) {
    return http.instance.post("/PostComment?" + params);
  }
  GetComments(params) {
    return http.instance.get("/GetComments?" + params);
  }
  GetFlagScreenshotListing(params) {
    return http.instance.get("/GetFlagScreenshotListing?" + params);
  }
  GetEventsPerMinutes(params) {
    return http.instance.get("/GetEventsPerMinutes?" + params);
  }
  GetDisplayScreen(params) {
    return http.instance.get("/GetDisplayScreen?" + params);
  }
  GetScreenshotDates(params) {
    return http.instance.get("/GetScreenshotDates?" + params);
  }
  GetUsersByCompanyId() {
    return http.instance.get("/GetUsersByCompanyId");
  }
  GetConfigUsersByCompanyId() {
    return http.instance.get("/GetConfigUsersByCompanyId");
  }
  ListTeamUsersByPM() {
    return http.instance.get("/ListTeamUsersByPM");
  }
  GetProjectListByUserIdAndUserType() {
    return http.instance.get("/GetProjectListByUserIdAndUserType");
  }
  async GetUsersForTimesheet(params) {
    return await http.instance.get("/GetUsersForTimesheet?" + params);
  }
  async ListTimesheetForWeek(params) {
    return await http.instance.get("/GetTimesheetDetails?" + params);
  }
  async ListTimesheetForWeekNew(params) {
    return await http.instance.get("/GetTimesheetDetailsNew?" + params);
  }
  async DeleteTrackEntryByProjectAndTaskId(param) {
    return await http.instance.get("/DeleteTrackEntryByProjectAndTaskId?" + param);
  }
  async TimerWeeklyTask() {
    return await http.instance.get("/TimerWeeklyTask");
  }
  async GetStartEndDate(param) {
    return await http.instance.get("/GetStartEndDate?" + param);
  }
  async GetAllApp(params) {
    return await http.instance.get("/GetAllApp?" + params);
  }
  async UpdateProductiveType(params) {
    return await http.instance.post("/UpdateProductiveType?" + params);
  }
  async AddManuallyBreak(params) {
    return await http.instanceModals.post("/ManualBreak", params);
  }

  async SaveTimeSheetEntry(params) {
    return http.instance.post("/SaveTimeSheetEntry?", { jsonData: params });
  }
  CommonDelete(params) {
    return http.instanceCommon.post("/Common/CommonDelete", { postString: params })
  }

  async PasteLastWeekTimesheet(params) {
    return http.instance.post("/PasteLastWeekTimesheet?" + params);
  }

  async TimerTeamStatus(params) {
    return http.instance.get("/TimerWeeklyTeamStatus?" + params);
  }

  async GetProjectWithTasks() {
    return http.instance.get("/GetProjectWithTasks");
  }
  async TrackEntryFlag(params) {
    return http.instance.get("/TrackEntryFlag?" + params);
  }
  ReportMain(params) {
    return http.instanceCommon.post("/Report/ReportMain", { postString: params });
  }
  FlagsPostComment(params) {
    return http.instance.post("/FlagPostComment", { postString: params });
  }
  UpdateTimeTrackEntry(params) {
    return http.instanceModals.post("/UpdateTimeTrackEntry", params);
  }
  ImportCategorySamplePost(param) {
    return http.instance.post("/ImportCategorySamplePost/", param);
  }
  BulkUploadCategoryType(params) {
    return http.instance.get("/BulkUploadCategoryType?" + params);
  }
  async CategoryTypeBulkUploadPost(params) {
    return await http.instance.post("/CategoryTypeBulkUploadPost", params);
  }

  DeleteData(params) {
    return http.instanceCommon.get("/Common/Delete?" + params)
  }
  CommonUpdateStatus(params) {
    return http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
  }
  // CommonDeleteData(params) {
  //   return http.instance.post("/Common/CommonDelete", { postString: params })
  // }

  CommonDeleteData(params) {
    return http.instanceModals.post("/Common/CommonDelete", { postString: params })
  }

  async GetModuleMasterIdByModuleName(params) {
    return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?" + params);
  }
  async GetConfigurationValueForCompanyByKey(param) {
    return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?" + param);
  }
  async GetHideContextMenuConfig() {
    return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
  }
  async TimesheetApprovalList(params) {
    return await http.instance.get("/TimesheetList?" + params);
  }
  async SaveHideContextMenuConfig(param) {
    return await http.instanceCommon.post("/Common/SaveHideContextMenuConfig?" + param);
  }
  async CopyLastWeekTimesheet(param) {
    return http.instance.post("/CopyLastWeekTimesheet?" + param);
  }
  async CopyToUserWeekTimesheet(param) {
    return http.instance.post("/CopyToUserWeekTimesheet?" + param);
  }

  async GetTimerUserTaskView(params) {
    return await http.instance.get("/TimerUserTaskView?" + params);
  }
  async SendForApproval(params) {
    return http.instance.post("/SendForApproval?" + params);
  }
  async DeleteTimeSheetRecord(params) {
    return http.instance.post("/DeleteMultipleTrackEntry", { jsonData: params });
  }
  async TimesheetHistoryList(params) {
    return await http.instance.get("/TimesheetHistoryList?" + params);
  }
  async GetTimesheetStatus(params) {
    return await http.instance.get("/GetTimesheetStatus?" + params);
  }
  async GetHolidayDate(params) {
    return await http.instance.get("/GetHolidayDate?" + params);
  }
  async GetCommentListByWeekDateRange(params) {
    return await http.instance.get("/GetCommentListByWeekDateRange?" + params);
  }
  ManagePin_UpPin(params) {
    return http.instanceCommon.post("/Common/ManagePin_UpPin?postString=" + params);
  }
  async AddTimesheetComment(url) {

    return await http.instance.post("/AddTimesheetComment?" + url);
  }
  async GetTemplateCategoryListForCA() {
    return await http.instance.get("/EmailTemplate/GetTemplateCategoryListForCA");
  }
  async GetTemplateSubCategoryList(params) {
    return await http.instance.get("/EmailTemplate/GetTemplateSubCategoryList?" + params);
  }
  async GetStatusList() {
    return await http.instance.get("/EmailTemplate/GetStatusList");
  }
  async AddAdvancedEmailTemplate(params) {
    return await http.instance.post("/EmailTemplate/Add", params);
  }
  async GetEmailTemplateColumnList(params) {
    return await http.instance.get("/EmailTemplate/GetEmailTemplateColumnList?" + params);
  }

  async GetEmailTemplateDetails(params) {
    return await http.instance.get("/EmailTemplate/GetEmailTemplateDetails?" + params);
  }
  async DirectoryListing(params) {
    return await http.instance.get("/User/DirectoryListing?" + params);
  }
  async GetContactsofUser(params) {
    return await http.instance.get("/User/GetContactsofUser?" + params);
  }
  async UploadFile(params) {
    return await http.instanceCommon.post("/Common/UploadFile", { postString: params });
  }
  async GetEmailTypes() {
    return await http.instance.get("/User/GetEmailTypes");
  }
  async AddUpdateEmail(params) {
    return await http.instance.post("/User/AddUpdateEmail", params);
  }
  async DeleteRecords(params) {
    return await http.instance.get("/User/DeleteRecords" + params);
  }
  async GetStateListByCountryId(param) {
    return http.instance.get("/User/GetStateListByCountryId?" + param);
  }
  async AddUpdateAddress(params) {
    return await http.instance.post("/User/AddUpdateAddress", params);
  }
  async AddUpdateContact(params) {
    return await http.instanceUser.post("/User/AddUpdateContact?", { postString: params });
  }
  async GetReviewTemplateData(params) {
    return await http.instance.get("/ReviewTemplate/GetReviewQuestionList?" + params);
  }
  async GetCustomFieldValue(params) {
    return await http.instance.get("/SMSTemplate/GetCustomFieldValue?" + params);
  }
  async GetTriggerFieldValue(params) {
    return await http.instance.get("/SMSTemplate/GetTriggerFieldValue?" + params);
  }
  async GetEmailProfile(params) {
    return await http.instance.get("/SMSTemplate/GetEmailProfile?" + params);
  }
  async SendTwilioSMS(params) {
    return await http.instance.post("/SMSTemplate/SendTwilioSMS", { postString: params });
  }
  async ManageSMSCampaign(params) {
    return await http.instance.post("/SMSTemplate/ManageSMSCampaign", { postString: params });
  }
  async SMSTemplateListing(params) {
    return await http.instance.get("/SMSTemplate/SMSTemplateListing?" + params);
  }
  async statusdropdown(params) {
    return await http.instanceCommon.get("/Common/GetStatusDDLData?" + params);
  }
  async GetNotificationTypeForCompany(params) {
    return await http.instance.get("/CompanyNotification/GetNotificationTypeForCompany");
  }
  async UpdateCompanyNotification(params) {
    return await http.instanceModals.post("/CompanyNotification/UpdateCompanyNotification", params);
  }
  HolidayBankListing(params) {
    return http.instance.get("/CompanyHoliday/GetHolidayBankListing?" + params);
  }
  async UserListing(params) {
    return await http.instanceUser.get("/User/UserListing?", { params });
  }
  async SearchCriteriaById(params) {

    return await http.instanceCommon.get("/Common/SearchCriteriaByIdforUpdateDelate?" + params);
  }
  async GetAllLicenses() {
    return await http.instanceUser.get("/User/GetAllLicenses");
  }
  async GetAllAddedLicenses(params) {
    return await http.instanceUser.get("/User/GetAllAddedLicenses?" + params);
  }
  async CheckExtraFeatures(params) {
    return await http.instanceUser.get("/User/CheckExtraFeatures?" + params);
  }
  async GetAssociateLicenses(params) {
    return await http.instanceCommon.get("/Wizard/GetAllAddedLicenses?" + params);
  }
  async GetAssociateDeAssociateLicense(params) {
    return await http.instanceCommon.get("/Wizard/AssociateDeAssociateLicense?" + params);
  }
  async addTitlepopup(params) {
    return await http.instanceUser.post("/User/addTitlepopup?" + params);
  }
  // async GetReviewTemplateData() {
  //   return await http.instance.get("/ReviewTemplate/GetReviewQuestionList");
  // }
  async AddUserData(params) {
    return await http.instanceUser.post("/User/AddUpdateUser", params);
  }
  AddHolidayInBank(params, obj) {
    return http.instanceModals.post("/CompanyHoliday/AddHolidayInBank?" + params, { postString: obj });
  }
  CurrencyManagementData(params) {
    return http.instance.post("/CurrencyManagement/Add", params);
  }
  async DeleteCurrencyLog(param) {
    return await http.instance.get("/CurrencyManagement/DeleteCurrencyLog?" + param);
  }
  async GetViewList(params) {
    return await http.instanceCommon.get("/FormLayout/GetViewList?" + params);
  }
  async ClearView(params) {
    return await http.instanceCommon.post("/FormLayout/ClearView", { postString: params });
  }
  async ViewListUsers(params) {
    return await http.instanceCommon.get("/FormLayout/ViewListUsers?" + params);
  }
  async ViewListFields(params) {
    return await http.instanceCommon.get("/FormLayout/ViewListFields?" + params);
  }
  async GetViewById(params) {
    return await http.instanceCommon.get("/FormLayout/GetViewById?" + params);
  }
  async SearchCriteriaListing(params) {
    return await http.instanceCommon.get("/Common/SearchCriteriaListing?" + params);
  }
  async GetFieldName(params) {
    return await http.instanceCommon.get("/Common/GetFieldName?" + params);
  }
  async SaveView(params) {
    return await http.instanceCommon.post("/FormLayout/SaveView", { postString: params });
  }
  async GetOperator(params) {
    return await http.instanceCommon.get("/Common/GetOperator?" + params);
  }
  SearchCriteriaByIdView(params) {
    return http.instanceCommon.get("/Common/ViewFlterById?" + params);
  }
  // async SearchCriteriaById(params) {
  //   return await http.instanceCommon.get("/Common/SearchCriteriaById?" + params);
  // }
  async SaveFilter(params) {
    return await http.instanceCommon.post("/Common/SaveFilter", { postString: params })
  }
  async ApplyView(params) {
    return await http.instanceCommon.post("/FormLayout/ApplyView", { postString: params });
  }
  async GetUserDetailByUserId(params) {
    return await http.instanceUser.get("/User/GetUserDetailByUserId?" + params);
  }
  SaveBonus(params) {
    return http.instanceUser.post("/User/SaveBonus?" + params);
  }
  GetModuleKeys(params) {
    return http.instanceCommon.get("/Common/GetModuleKeys?" + params);
  }
  GetModuleForConfiguration(params) {
    return http.instanceCommon.get("/Configuration/GetAllModule?" + params);
  }
  async ManageDocUserStorageSettingData(params) {
    return http.instanceCommon.post("/Common/ManageDocUserStorageSettingData", params);
  }

  // Asset management common api

  GetCountryDDL(params) {
    return http.instanceCommon.get("/Common/GetDDLData?" + params);
  }
  async GetProgresSetupStatusDetail(params) {
    return await http.instanceCommon.get("/Common/GetProgresSetupStatusDetail?" + params);
  }

  async GetCompanyLogo(params) {
    return await http.instanceCommon.get("/Common/GetCompanyLogo?" + params);
  }
  async SaveUserForm(params) {
    return await http.instanceCommon.post("/FormLayout/SaveDynamicForm", { postString: params });
  }
  GetAssignBonusListing(params) {
    return http.instanceUser.get("/User/GetAssignBonusListing?" + params);
  }
  async ResetPasswordByAdmin(params) {

    return await http.instanceUser.post("/Account/ResetPasswordByAdmin", params)
  }
  async GetCommunicationTypes() {
    return await http.instanceUser.get("/Communication/GetCommunicationTypes")
  }
  GetpriorityList(params) {
    return http.instance.get("/Priority/GetList?" + params);
  }
  GetQuestionList(params) {
    return http.instance.get("/Question/GetList?" + params);
  }
  async GetUserDetailById(params) {
    return await http.instanceUser.get("/Communication/GetUserDetailByUserId?" + params)
  }
  async GetReportManager(params) {
    return await http.instanceUser.get("/Communication/GetReportManager?" + params)
  }
  async GetRootManagerDetails(params) {
    return await http.instanceUser.get("/Communication/GetRootManagerDetails?" + params)
  }
  async GetNotificationTypes(params) {
    return await http.instanceUser.get("/User/GetNotificationTypes?" + params)
  }
  async Addeditfeedbackdata(params) {
    return await http.instanceUser.post("/FeedBack/AddEditFeedback", params);
  }
  Getfeedbackuserlisting(params) {
    return http.instanceUser.get("/FeedBack/userFeedbacklisting?" + params);
  }
  GetCustomizeShortcutMenu() {
    return http.instanceCommon.get("/Dashboard/FullMenu");
  }
  GetFavDashboard() {
    return http.instanceCommon.get("/Common/GetFavDashboard");
  }
  async SaveShortcutData(params) {

    return await http.instanceCommon.post("/Dashboard/SaveShortcutData", { postString: params });
  }
  GetConfigurationkeys(params) {
    return http.instanceCommon.get("/Wizard/GetModuleKeys?" + params);
  }
  GetUserModuleskeys(params) {
    return http.instanceCommon.get("/Wizard/GetUserModules?" + params);
  }
  UploadBulkUser(params) {
    return http.instanceUser.post("/User/BulkUploadUser", params);
  }
  ChangeUserStatus(params) {
    return http.instanceUser.post("/User/UpdateStatus?" + params);
  }
  GetUserfeedback(params) {
    return http.instanceUser.get("/FeedBack/userFeedbackListMember?" + params);
  }
  getfedbackdata(param) {
    return http.instanceUser.get("/FeedBack/GetFeedbackuserdatabyId?" + param);
  }
  UpdateUserConfigData(params) {
    return http.instanceUser.post("/User/UpdateUserConfigData?", params);
  }
  AddupdateAdditionalAddress(params) {
    return http.instanceUser.post("/User/AddUpdateAdditionalAddress?", params)
  }
  GetAdditionalAddressList(param) {
    return http.instanceUser.get("/User/GetAdditionalAddresses?" + param);
  }
  PasswordAdUser(params) {
    return http.instanceUser.post("/User/PasswordAdUser", params);
  }
  GetUserEmailList(param) {
    return http.instanceUser.get("/User/GetUserEmailList?" + param);
  }
  DeleteAddtionalEmail(param) {
    return http.instanceUser.get("/User/DeleteAddtionalEmail?" + param);
  }

  async AddUpdateAdditionalEmail(params) {
    return await http.instanceUser.post("/User/AddUpdateAdditionalEmail?", { postString: params });
  }
  GetUserNoteList(param) {
    return http.instanceUser.get("/User/GetUserNoteList?" + param);
  }
  DeleteUserNotes(param) {
    return http.instanceUser.get("/User/DeleteUserNotes?" + param);
  }
  async AddUpdateUserNote(params) {
    return await http.instanceUser.post("/User/AddUpdateUserNote?", { postString: params });
  }
  SavePostcomment(params) {
    return http.instanceUser.post("/User/SavePostcomment?", { postString: params });
  }
  async GetCommentById(params) {
    return await http.instanceUser.get("/User/GetCommentById?" + params);
  }
  async GetUserBreakListing(params) {
    return await http.instanceUser.get("/User/GetUserBreakListing?" + params);
  }
  async GetShiftTiming(params) {
    return await http.instanceUser.get("/User/GetShiftTiming?" + params);
  }
  UpdateBillableStatus(params) {
    return http.instanceUser.get("/User/UpdateBillableStatus?" + params);
  }
  async AddUpdateUserBreak(params) {
    return await http.instanceUser.post("/User/AddUpdateUserBreak?" + params);
  }
  async GetUserSkillList(params) {
    return await http.instanceUser.get("/User/GetUserSkillList?" + params);
  }
  async ListSkillForAssignment(params) {
    return await http.instanceUser.get("/User/ListSkillForAssignment?" + params);
  }
  AddUpdateUserSkillAssignment(params) {
    return http.instanceUser.post("/User/AddUpdateUserSkillAssignment", params)
  }
  async GetOrgReportTeam(params) {
    return await http.instanceUser.get("/Communication/GetOrgReportTeam?" + params)
  }
  async GetOrgReportSecondaryTeam(params) {
    return await http.instanceUser.get("/Communication/GetOrgReportSecondaryTeam?" + params)
  }
  async GetAllEmployee(params) {
    return await http.instanceUser.get("/Communication/GetAllEmployee?" + params)
  }
  async GetSecondaryManager(params) {
    return await http.instanceUser.get("/Communication/GetSecondaryManager?" + params)
  }
  async UpdateUserSkill(params) {
    return await http.instanceUser.get("User/UpdateUserSkill?" + params);
  }
  async SaveComment(params) {
    return await http.instanceUser.post("User/SaveComment?" + params);
  }
  async CommentList(params) {
    return await http.instanceUser.get("/User/CommentList?" + params);
  }
  async DeleteUserAddress(params) {
    return await http.instanceUser.get("/User/DeleteUserAddress?" + params);
  }

  async SetPrimaryAddress(params) {
    return await http.instanceUser.post("/User/setPrimaryAddress?" + params);
  }
  GetPerformanceReviewList(params) {
    return http.instanceUser.get("/Review/GetPerformanceReviewList?" + params);
  }
  GetReviewTemplateList() {
    return http.instanceUser.get("/Review/GetReviewTemplateList");
  }
  GetReviewerList(params) {
    return http.instanceUser.get("/Review/GetReviewerList?" + params);
  }
  GetUserPhoneList(param) {
    return http.instanceUser.get("/User/GetUserPhoneList?" + param);
  }
  DeleteAddtionalPhone(param) {
    return http.instanceUser.get("/User/DeleteAddtionalPhone?" + param);
  }
  async GetProficiencyHistoryList(params) {
    return await http.instanceUser.get("/User/GetProficiencyHistoryList?" + params);
  }
  async AddUpdateGoal(params) {
    return await http.instanceUser.post("/User/AddUpdateGoal", params)
  }
  async GetUserGoalList(params) {
    return await http.instanceUser.get("/User/UserGoalListing?" + params);
  }
  async GetUserImage(params) {
    return await http.instanceCommon.get("/Common/GetUserImage?" + params);
  }
  async AddSecondaryManagerData(params) {
    return await http.instanceUser.post("/Communication/AddSecondaryManagerData?" + params)
  }
  async DeletePrimarySecondaryMember(params) {
    return await http.instanceUser.post("/Communication/DeletePrimarySecondaryMember?" + params)
  }
  GetEncryptPassword(param) {
    return http.instanceUser.get("/User/GetPasswordAndSalt?" + param);
  }
  DeleteBounceLeave(params) {
    return http.instanceUser.post("/User/DeleteBounceLeave?", { postString: params });
  }
  async UpdateStatus(params) {
    return await http.instanceUser.post("/FeedBack/UpdateStatusCommon?" + params)
  }
  SaveReview(param) {
    return http.instanceUser.post("/Review/SaveReview", param);
  }
  UpdateReviewStatus(param) {
    return http.instanceUser.post("/Review/UpdateStatusReview?" + param);
  }
  GetEditReviewerList(param) {
    return http.instanceUser.get("/Review/EditReviewerList?" + param);
  }
  async AddNotificationData(params) {
    return await http.instanceUser.post("/Account/AddNotificationData?", params)
  }
  async AddPrimaryAndSecondaryTeamMember(params) {
    return await http.instanceUser.post("/Communication/AddPrimaryAndSecondaryTeamMember?" + params)
  }
  GetprojectDDL() {
    return http.instanceUser.get("/User/GetProjects");
  }
  async GetImportSampleData() {
    return await http.instanceCommon.get("/Common/GetImportSampleData");
  }

  async GetUserDetailsByUserId() {
    return await http.instanceCommon.get("/Common/GetUserDetailByUserId");
  }
  async UpdateUserProfile(params) {
    return await http.instanceCommon.post("/Common/UpdateUserProfile", { postString: params });
  }
  async GetCompanyShiftOrLocation(params) {
    return await http.instanceCommon.get("/Common/GetCompanyShiftOrLocation?" + params);
  }
  GetUserTypeRole(param) {
    return http.instanceCommon.get("/Roles/get_roles_list?" + param);
  }
  GetTimerZone() {
    return http.instanceUser.get("/User/GetConfigurationValueByKey");
  }
  GetHourlyRate(param) {
    return http.instanceUser.get("/User/GetConfigurationValueByKey?" + param);
  }
  DeleteUser(param) {
    return http.instanceUser.post("/User/DeleteUser?" + param);
  }
  CheckEmailExist(param) {
    return http.instanceUser.get("/User/CheckEmailUserExist?" + param);
  }
  SetDefaultProject(param) {
    return http.instanceUser.post("/User/SetDefaultProject?" + param);
  }
  SendMail(param) {
    return http.instanceUser.get("/User/SendMail?" + param);
  }
  SendMailInduction(param) {
    return http.instanceUser.get("/User/SendMailInduction?" + param);
  }
  SendFeedbackMail(param) {
    return http.instanceUser.post("/User/SendFeedbackMail?" + param);
  }
  GetPasswordPolicyByCompanyId() {
    return http.instanceUser.get("/User/GetPasswordPolicyByCompanyId");
  }
  GenerateRandomPassword(param) {
    return http.instanceUser.post("/Account/GenerateRandomPassword", param);
  }
  async GetLicenseList(params) {
    return await http.instanceCommon.get("/Common/GetAllLicenses?" + params);
  }
  async MakeProjectDefaultForUsers(params) {
    return await http.instanceUser.post("/User/MakeProjectDefaultForUsers?" + params);
  }
  async GetCategoryListing(params) {
    return await http.instanceTimer.get("/GetCategoryListing?" + params);
  }
  async GetDeviceListByCompanyId(params) {
    return await http.instanceTimer.get("/GetDeviceListByCompanyId?" + params);
  }
  async GetUserListByCompanyId(params) {
    return await http.instanceTimer.get("/GetUserListByCompanyId?" + params);
  }
  async MapUser(params) {
    return await http.instanceTimer.post("/MapUser?" + params);
  }
  async GetProfileListByMachineId(params) {
    return await http.instanceTimer.get("/GetProfileListByMachineId?" + params);
  }
  async DeleteMachines(params) {
    return await http.instanceTimer.get("/DeleteMachines?" + params);
  }
  async UnMapUser(params) {
    return await http.instanceTimer.post("/UnMapUser?" + params);
  }
  async GetDropdownlistApplicationNotAssigned(params) {
    return await http.instanceTimer.get("/GetDropdownlistApplicationNotAssigned?" + params);
  }
  async GetCategoryById(params) {
    return await http.instanceTimer.get("/GetCategoryById?" + params);
  }
  async ManageCategory(params) {
    return await http.instanceTimer.post("/ManageCategory", { postString: params });
  }
  ImportCategorySamplePost(param) {
    return http.instanceTimer.post("/ImportCategorySamplePost/", param);
  }
  BulkUploadCategoryType(params) {
    return http.instanceTimer.get("/BulkUploadCategoryType?" + params);
  }
  async CategoryTypeBulkUploadPost(params) {
    return await http.instanceTimer.post("/CategoryTypeBulkUploadPost", params);
  }
  async GetScreenShotListing(params) {
    return await http.instanceTimer.get("/GetScreenShotListing?" + params);
  }
  // async GetAssignDepartmentByApplicationId(params) {
  //   return await http.instanceTimer.get("/GetAssignDepartmentByApplicationId?" + params);
  // }
  async GetAssignDepartmentByApplicationId(params) {
    return await http.instanceTimer.get("/GetAssignDepartmentByApplicationId?" + params);
  }
  async SaveAssignDepartment(params) {
    return await http.instanceTimer.post("/SaveAssignDepartment", { postString: params });
  }
  async AddUserInitialSetup(params) {
    return await http.instanceCommon.post("/Common/AddUserInitialSetup", { postString: params });
  }
  GetRemainingFreeTrailDays() {
    return http.instanceCommon.get("/Configuration/GetRemainingFreeTrailDays");
  }
  GetUserLicenseModules() {
    return http.instanceUser.get("/User/GetUserLicenseModules");
  }
  async UpdateUserSettingConfig(param) {
    return await http.instanceCommon.post("/Common/UpdateUserSettingConfig?" + param);
  }
  async GetUserSettingConfigByType(param) {
    return await http.instanceCommon.get("/Common/GetUserSettingConfigByType?" + param);
  }
  GetCompanyLocation() {
    return http.instanceCommon.get("/Configuration/GetCompanyLocation");
  }
  GetDepartmentListByCompanyId(params) {
    return http.instanceUser.get("/User/GetDepartmentListByCompanyId?" + params);
  }
  GetAssignTeam(params) {
    return http.instanceUser.get("/User/GetAssignTeam?" + params);
  }
  UpdateLinkVisitedInformation(param) {
    return http.instanceCommon.get("/Common/UpdateLinkVisitedInformation?" + param);
  }
  AddSearchUser(params) {
    return http.instanceUser.post("/User/AddSearchUser?" + params);
  }
  UploadImageFile(params) {
    return http.instanceUser.post("/User/UploadImageFile", params);
  }
  LeaveBonus(params) {
    return http.instanceUser.get("/User/LeaveBonus?" + params);
  }
  SaveBonusLeave(params) {
    return http.instanceUser.post("/User/SaveAssignBonus?" + params);
  }
  GetLeaveBonusHistory(params) {
    return http.instanceUser.get("/User/GetLeaveBonusHistory?" + params);
  }
  LoadGhanttChart(params) {
    return http.instanceUser.get("User/LoadGhanttChart?" + params);
  }
  GetClientListing(params) {
    return http.instance.get("/Common/GetClientListing?" + params);
  }
  async CheckExtraFeatures(params) {
    return http.instanceCommon.get("/Wizard/CheckExtraFeatures?" + params);
  }
  GetClientdetail(params) {
    return http.instance.get("/Common/GetClientdetail?" + params);
  }
  async AddClient(params) {
    return await http.instanceCommon.post("/Wizard/AddClient", { postString: params });
  }
  async GetClientDetailByEmail(params) {
    return http.instanceCommon.get("/Wizard/GetClientDetail?" + params);
  }
  GetProjectDetail(params) {
    return http.instance.get("/Common/GetProjectDetail?" + params);
  }
  async UpdateStatusAccount(params) {
    return await http.instance.get("/Common/UpdateStatusAccount?" + params);
  }
  async SendActivationMail(params) {
    return http.instanceCommon.get("/Wizard/SendActivationMail?" + params);
  }
  async GetModuleIdByModuleName(params) {
    return http.instanceCommon.get("/Common/GetModuleIdByModuleName?" + params);
  }
  async GetConfigurationkeysData(params) {
    return http.instance.get("/Common/GetModuleKeys?" + params);
  }
  async GetTemplateListBySetupId(params) {
    return http.instance.get("/Common/GetTemplateListBySetupId?" + params);
  }
  async LoadTemplateBySetupId(params) {
    return http.instance.get("/Common/LoadTemplateBySetupId?" + params);
  }
  async GetCurrencyDDL() {
    return await http.instanceCommon.get("/Common/GetCurrency");
  }
  async GetAccountProfile(params) {
    return http.instance.get("/Common/GetAccountProfile?" + params);
  }
  async SaveAccount(params) {
    return await http.instance.post("/Common/SaveAccount", { postString: params });
  }
  async GetClientParentByCompanyId() {
    return await http.instance.get("/Common/GetClientParentByCompanyId");
  }
  async GetClientOwner(params) {
    return http.instanceCommon.get("/Common/GetClientOwner?" + params);
  }
  async GetTimeZoneList() {
    return await http.instanceCommon.get("/Wizard/GetTimeZoneList");
  }
  async GetAllRoles(params) {
    return http.instance.get("/Common/GetAllRoles?" + params);
  }
  async GetTabsContent(params) {
    return http.instanceCommon.get("/Common/GetTabsContent?" + params);
  }
  async GetClientDetailByClientId(params) {
    return http.instanceCommon.get("/Wizard/GetClientProfile?" + params);
  }

  async GetQuotationListByQuotationType(params) {
    return http.instance.get("/Common/GetQuotationListByQuotationType?" + params);
  }
  async GetContractListing(params) {
    return http.instance.get("/Common/GetContractListing?" + params);
  }
  async CheckListListing(params) {
    return await http.instance.get("/CheckList/GetCheckList?" + params);
  }
  SaveCheckList(params) {
    return http.instance.post("/CheckList/AddChecklistData", { postString: params });
  }
  async GetCheckListDeatils(params) {
    return await http.instance.get("/CheckList/GetCheckListDeatils?" + params);
  }
  async LoadPlansMappingSubscription(param) {
    return http.instanceBilling.get("/GetPlanMappingListNew?" + param);
  }
  SaveTeam(params) {
    return http.instance.post("/CheckList/SaveTeam", { postString: params });
  }
  FetchTeamList(params) {
    return http.instance.get("/CheckList/GetTeamList?" + params);
  }
  async ListCreditHistory(params) {
    return http.instance.get("/Common/ListCreditHistory?" + params);
  }
  async GetTaxsDiscounts(params) {
    return http.instanceBilling.get("/GetTaxsDiscounts?" + params);
  }
  async SaveUpdateCreditInfo(params) {
    return http.instance.post("/Common/SaveUpdateCreditInfo", { postString: params });
  }
  async ListAdditionalNewContact(params) {
    return http.instance.get("/Common/ListAdditionalNewContact?" + params);
  }
  async GetAdditionalNewContactsNotWithClient(params) {
    return http.instance.get("/Common/GetAdditionalNewContactsNotWithClient?" + params);
  }
  async SubmitAdditionalNewContacts(params) {
    return http.instance.post("/Common/SubmitAdditionalNewContacts", { postString: params });
  }
  async CompanyHolidayList(params) {
    return http.instance.get("/CompanyHoliday/CompanyHolidayList?" + params);
  }
  async SaveCompanyHoliday(params) { 
    return http.instance.post("/CompanyHoliday/AddCompanyHoliday", { postString: params });
  }
  async GetPlanListing(param) {
    return http.instanceBilling.get("/GetPlanListing?" + param);
  }
  async GenrateInvoice(params) {
    return http.instanceBilling.get("/GenrateInvoice?" + params);
  }
  async GetAllSubscriptionDiscount(params) {
    return http.instanceSubscritption.get("/Registration/GetAllSubscriptionDiscount?" + params);
  }
  async getSubscriptionListing(params) {
    return http.instance.get("/Client/GetSubscriptionsList?" + params);
  }
  async getPaymentsListing(params) {
    return http.instance.get("/Client/GetPaymentsList?" + params);
  }
  async GetInvoiceListing(params) {
    return http.instance.get("/Common/GetInvoiceListing?" + params);
  }
  async GetPaymentHistoryPopUp(params) {
    return http.instance.get("/Common/PaymentHistoryPopUp?" + params);
  }
  async GetTemplate(params) {
    return http.instance.get("/Common/GetTemplateDetails?" + params);
  } 
  async GetInvoiceData(params) {
    return http.instance.get("/Common/GetInvoiceBillingDivisionDetails?" + params);
  } 
  SaveInvoiceData(params, obj) {
    return http.instanceModals.post("/Common/SaveInvoiceData?" + params, { postString: obj });
  }
  async DeleteContractHoliday(params){
    return http.instance.get("/CompanyHoliday/DeleteHoliday?" + params);
  }
  async HolidayBankList(params){
    return http.instance.get("/CompanyHoliday/HolidayBankList?" + params);
  }
  async SaveBankHoliday(params) {
    return await http.instance.post("/CompanyHoliday/SaveBankHoliday" , { postString: params });
  }
  async GetInvoiceData(params) {
    return http.instance.get("/Common/GetInvoiceBillingDivisionDetails?" + params);
  }

  SaveInvoiceData(params, obj) {
    return http.instanceModals.post("/Common/SaveInvoiceData?" + params, { postString: obj });
  }
 
  async SaveNewContacts(params) {
    return await http.instanceCommon.post("/Wizard/SaveNewContacts", { postString: params });
  }
  async LoadPlansMappingSubscriptionById(params) {
    return http.instanceBilling.get("/GetPlanMappingListNewForEdit?" + params);
  }
  async GetPlanUnitHistoryForSubscription(params) {
    return http.instanceBilling.get("/GetPlanUnitHistoryForSubscription?" + params);
  }
  DeAsscoiate(params) {
    return http.instanceBilling.post("/SavePlanMapping", params);
  }
  async GetPlanMappingListForSubscriptionPlan(param) {
    return http.instanceBilling.get("/GetPlanMappingListForSubscriptionPlan?" + param);
 }
 async GetContractsListByHolidayId(params){
  return http.instance.get("/CompanyHoliday/GetContractsListByHolidayId?" + params);
 }
async UpdateClientInvoiceStatus(params) {
  return http.instance.get("/Common/UpdateClientInvoiceStatus?" + params);
}
async GetSubscribedPlanDetails(params) {
  return http.instanceBilling.get("/GetSubscribedPlanDetails?" + params);
}
async GetMeterNameDDlData(params) {
  return http.instanceBilling.get("/Meter/GetMeterNameDDlData?" + params);
}
async GetSerialnumberByMeterId(params) {
  return http.instanceBilling.get("/Meter/GetSerialnumberByMeterId?" + params);
}
async GetMeterMappingDetails(params) {
  return http.instanceBilling.get("/Meter/GetMeterMappingDetails?" + params);
}
async GetCompanyPlans(params) {
  return http.instanceBilling.get("/Meter/GetCompanyPlans?" + params);
}
async MeterMapping(params) {
  return http.instanceBilling.post("/Meter/MeterMapping", { postString: params });
}
async GetMapMeterById(params) {
  return http.instanceBilling.get("/Meter/GetMapMeterById?" + params);
}
async getInvoiceDetails(params) {
  return http.instanceBilling.get("/getInvoiceDetails?" + params);
}
async SaveMeterReplacement(params) {
  return http.instanceBilling.post("/Meter/SaveMeterReplacement", { postString: params });
}
async GetInvoicePartailPaymentDetails(params) {
  return http.instance.get("/Common/GetInvoicePartailPaymentDetails?" + params);
}
SaveInvoicePartialPayment(obj) {
  return http.instance.post("/Common/SaveInvoicePartialPayment?" , { postString: obj });
}
async ViewHistory(params) {
  return http.instanceBilling.get("/Meter/ViewHistory?" + params);
}
async AddMemberInProject(params){ 
  return await http.instance.get("/CheckList/GetMemberListing?"+params);
}
async AssociateUserWithTeam(params) { 
  return http.instance.post("/CheckList/AssociateUserWithTeam", { postString: params });
}
async DeAssociateUserWithTeam(params) { 
  return http.instance.post("/CheckList/DeAssociateUserWithTeam", { postString: params });
}
async DeleteTeam(params){ 
  return await http.instance.get("/CheckList/GetDeleteTeam?"+params);
}
SaveAdditionalOwner(obj) {
  return http.instance.post("/Common/SaveAdditionalOwner?" , { postString: obj });
}
async GetGetAdditionalOwnerListing(params) {
  debugger
  return http.instance.get("/Common/GetAdditionalOwnerListing?" + params);
}
DeleteAdditionalOwner(params) {
  return http.instance.post("/Common/DeleteAdditionalOwner", { postString: params })

}
async GetBillAttachMentList(params) {
  return http.instanceBilling.get("/Get-bill-attachment-list?" + params);
}
async SaveBillAttachment(params) {
  return http.instanceBilling.post("/save-attachment?", params);
}
GetRateScheduleListForSubscription(params){
  return http.instanceBilling.get("/GetRateScheduleListForSubscription?" + params);
}
GetRateSchedulePlanServices(params){
  return http.instanceBilling.get("/GetRateSchedulePlanServices?" + params);
}
async GetPlanRateListing(params) {
  debugger
  return await http.instanceBilling.get("/GetPlanRateListing?" + params);
}
async DeleteAttachment(params) {
  return http.instanceBilling.delete("/Delete-bill-attachment?" + params);
}
SaveRateSchedulePlanServices(params) {
  return http.instanceBilling.post("/SaveRateSchedulePlanServices" , params);
}
async ViewRateSchedulePlanServicesHistory(param){
  return http.instanceBilling.get("/ViewRateSchedulePlanServicesHistory?"+ param);
}
async SaveRateScheduleOnBehalfOfPlan(params) {
  return http.instanceBilling.post("/SaveRateScheduleOnBehalfOfPlan" , params);
}
}
export default new DataService();
