import Vue from "vue";
import VueRouter from "vue-router";
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from "../views/auth/Callback.vue";
import Unauthorized from "../views/auth/Unauthorized.vue";
import authService from "../services/authservice.js";
import DataService from "../services/DataService";
import common from "../assets/common/vue-common.js";
import { BIconCheck2Square } from "bootstrap-vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/logout",
    name: "logout",
    component: Unauthorized,
  },
  {
    path: "/callback",
    name: "callback",
    component: Callback,
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
  },

  {
    path: "/Company/Index",
    name: "company",
    component: () => import("../views/Company/Company.vue"),
    meta: {
      title: "Company setup",
      breadcrumb: [{ name: "Company" }],
    },
  },
  // {
  //   path: "/Goal/Add",
  //   name: "AddGoal",
  //   component: () => import("../views/Users/AddGoal.vue"),
  //   meta: {
      
  //     checkPrivilege:false,
  //     title: "update User",
  //     breadcrumb: [{ name: "AddGoal" }],
  //   },
  // },
  
  {
    path: "/AnnouncementMessage/Index",
    name: "announcementmessage",
    component: () => import("../views/Company/AnnouncementMessage/List.vue"),
    meta: {
      title: "Manage Announcement Message",
      breadcrumb: [{ name: "Announcement Message" }],
    },
  },
  {
    path: "/Break/Index",
    name: "break",
    component: () => import("../views/Company/Break/List.vue"),
    meta: {
      title: "Manage Break",
      breadcrumb: [{ name: "Break" }],
    },
  },
  {
    path: "/CannedReply/Index",
    name: "cannedreply",
    component: () => import("../views/Company/CannedReply/List.vue"),
    meta: {
      title: "Manage Canned Reply",
      breadcrumb: [{ name: "CannedReply" }],
    },
  },
  {
    path: "/Category/Index",
    name: "category",
    component: () => import("../views/Company/Category/List.vue"),
    meta: {
      title: "Manage Category",
      breadcrumb: [{ name: "Category" }],
    },
  },
  {
    path: "/Channel/Index",
    name: "channel",
    component: () => import("../views/Company/Channel/List.vue"),
    meta: {
      title: "Manage Channel",
      breadcrumb: [{ name: "Channel" }],
    },
  },
  {
    path: "/CompanyHoliday/Index",
    name: "companyholiday",
    component: () => import("../views/Company/CompanyHoliday/List.vue"),
    meta: {
      // checkPrivilege:false,
      // controllerName: "hr",
      // actionName: "companyholidaylist",
      title: "Manage Company Holiday",
      breadcrumb: [{ name: "CompanyHoliday" }],
    },
  },
  {
    path: "/CompanyNotification/Index",
    name: "companynotification",
    component: () => import("../views/Company/CompanyNotification/List.vue"),
    meta: {
      controllerName: "notification",
      actionName: "companyindex",
      title: "Manage Company Notification",
      breadcrumb: [{ name: "CompanyNotification" }],
    },
  },
  {
    path: "/ContactType/Index",
    name: "contacttype",
    component: () => import("../views/Company/ContactType/List.vue"),
    meta: {
      title: "Manage Contact Type",
      breadcrumb: [{ name: "ContactType" }],
    },
  },
  {
    path: "/CurrencyManagement/Index",
    name: "currencymanagement",
    component: () => import("../views/Company/CurrencyManagement/List.vue"),
    meta: {
      controllerName: "currencymanagement",
      actionName: "add",
      title: "Manage Currency Management",
      breadcrumb: [{ name: "CurrencyManagement" }],
    },
  },
  {
    path: "/Department/Index",
    name: "deparment",
    component: () => import("../views/Company/Department/List.vue"),
    meta: {
      title: "Manage Department",
      breadcrumb: [{ name: "Department" }],
    },
  },
  {
    path: "/Designation/Index",
    name: "designation",
    component: () => import("../views/Company/Designation/List.vue"),
    meta: {
      title: "Manage Designation",
      breadcrumb: [{ name: "Designation" }],
    },
  },
  {
    path: "/EmailTemplate/Index",
    name: "emailtemplate",
    component: () => import("../views/Company/EmailTemplate/List.vue"),
    meta: {
      title: "Manage Email Template",
      breadcrumb: [{ name: "EmailTemplate" }],
    },
  },
  {
    path: "/Feedback/Index",
    name: "feedback",
    component: () => import("../views/Company/Feedback/List.vue"),
    meta: {
      controllerName: "ticketing",
      actionName: "Feedback",
      title: "Manage Feedback",
      breadcrumb: [{ name: "Feedback" }],
    },
  },
  {
    path: "/Greeting/Index",
    name: "greeting",
    component: () => import("../views/Company/Greeting/List.vue"),
    meta: {
      title: "Manage Greeting",
      breadcrumb: [{ name: "Greeting" }],
    },
  },
  {
    path: "/Question/Index?reqhFrom=0",
    name: "Question",
    component: () => import("../views/Company/Question/List.vue"),
    meta: {
      title: "Manage Question",
      breadcrumb: [{ name: "Question" }],
    },
  },
  {
    path: "/IndustryType/Index",
    name: "industrytype",
    component: () => import("../views/Company/IndustryType/List.vue"),
    meta: {
      title: "Manage Industry Type",
      breadcrumb: [{ name: "IndustryType" }],
      checkPrivilege: false,
    },
  },
  {
    path: "/Immigration/Index",
    name: "immigration",
    component: () => import("../views/Company/Immigration/List.vue"),
    meta: {
      title: "Manage Immigration",
      breadcrumb: [{ name: "Immigration" }],
    },
  },
  {
    path: "/Location/Index",
    name: "location",
    component: () => import("../views/Company/Location/List.vue"),
    meta: {
      title: "Manage Location",
      breadcrumb: [{ name: "Location" }],
    },
  },
  {
    path: "/LoginAuthorization/Index",
    name: "loginauthorization",
    component: () => import("../views/Company/LoginAuthorization/List.vue"),
    meta: {
      title: "Manage Login Authorization",
      breadcrumb: [{ name: "LoginAuthorization" }],
    },
  },
  {
    path: "/PaymentGateway/Index",
    name: "paymentgateway",
    component: () => import("../views/Company/PaymentGateway/List.vue"),
    meta: {
      title: "Manage Payment Gateway",
      breadcrumb: [{ name: "PaymentGateway" }],
    },
  },
  {
    path: "/ProvisionalRight/Index",
    name: "provisionalright",
    component: () => import("../views/Company/ProvisionalRight/List.vue"),
    meta: {
      title: "Manage Provisional Right",
      breadcrumb: [{ name: "ProvisionalRight" }],
    },
  },
  {
    path: "/ReviewTemplate/Index",
    name: "reviewtemplate",
    component: () => import("../views/Company/ReviewTemplate/List.vue"),
    meta: {
      title: "Manage Review Template",
      breadcrumb: [{ name: "ReviewTemplate" }],
    },
  },
  {
    path: "/Stage/Index",
    name: "stage",
    component: () => import("../views/Company/Stage/List.vue"),
    meta: {
      title: "Manage Stage",
      breadcrumb: [{ name: "Stage" }],
    },
  },
  {
    path: "/Service/Index",
    name: "service",
    component: () => import("../views/Company/Service/List.vue"),
    meta: {
      // controllerName: "servicemaster",
      // actionName: "List",
      title: "Manage Service",
      breadcrumb: [{ name: "Service" }],
    },
  },
  {
    path: "/Shift/Index",
    name: "shift",
    component: () => import("../views/Company/Shift/List.vue"),
    meta: {
      title: "Manage Shift",
      breadcrumb: [{ name: "Shift" }],
    },
  },
  {
    path: "/Skill/Index",
    name: "skill",
    component: () => import("../views/Company/Skill/List.vue"),
    meta: {
      title: "Manage Skill",
      breadcrumb: [{ name: "Skill" }],
    },
  },
  {
    path: "/Status/Index",
    name: "status",
    component: () => import("../views/Company/Status/List.vue"),
    meta: {
      title: "Manage Status",
      breadcrumb: [{ name: "Status" }],
    },
  },
  {
    path: "/Storage/Index",
    name: "storage",
    component: () => import("../views/Company/Storage/List.vue"),
    meta: {
      title: "Manage Storage",
      breadcrumb: [{ name: "Storage" }],
    },
  },
  {
    path: "/UserTitle/Index",
    name: "usertitle",
    component: () => import("../views/Company/UserTitle/List.vue"),
    meta: {
      controllerName: "user",
      actionName: "UserTitle",
      title: "Manage User Title",
      breadcrumb: [{ name: "UserTitle" }],
    },
  },
  {
    path: "/Roles/Index",
    name: "Roles",
    component: () => import("../views/Roles/RolesListing.vue"),
    meta: {
      title: "Manage Roles",
      breadcrumb: [{ name: "Roles" }],
    },
  },
  {
    path: "/Roles/UpdateRoles/:id",
    name: "Manage",
    component: () => import("../views/Roles/UpdateRoles.vue"),
    meta: {
      title: "Manage",
      breadcrumb: [
        { name: "Roles", link: "/Roles/index" },
        { name: "Manage" },
      ],
    },
  },
  {
    path: "/Roles/AddRoles",
    name: "Manage",
    component: () => import("../views/Roles/UpdateRoles.vue"),
    meta: {
      title: "Manage",
      breadcrumb: [
        { name: "Roles", link: "/Roles/index" },
        { name: "Manage" },
      ],
    },
  },
  {
    path: "/EmailTemplate/Addnew",
    name: "AddEmailTemplate",
    component: () => import("../views/Company/EmailTemplate/List.vue"),
    meta: {
      title: "Add Email Template",
      breadcrumb: [{ name: "EmailTemplate" }],
    },
  },
  {
    path: "/User/DirectoryListing",
    name: "DirectoryListing",
    component: () => import("../views/Company/User/List.vue"),
    meta: {
      title: "Company Directory",
      breadcrumb: [{ name: "DirectoryListing" }],
    },
  },
  { path: "/SMSTemplate/Index",
    name: "SMSTemplate",
    component: () => import("../views/Company/SMSTemplate/List.vue"),
    meta: {
      title: "Manage SMS Template",
      breadcrumb: [{ name: "SMSTemplate"}]
    }
  },{
    path: "/User/Index",
    name: "UserIndex",
    component: () => import("../views/Users/UserListing.vue"),
    meta: {
      checkPrivilege:false,
      title: "User Listing",
      breadcrumb: [{ name: "Users" }],
    },
  },
  {
    path: "/User/Add",
    name: "AddUser",
    component: () => import("../views/Users/AddUser.vue"),
    meta: {
      title: "Add User",
      breadcrumb: [
        { name: "Users", link:"/User/Index" },
        { name: "Add User"}
      ],
    },
  },
  {
    path: "/User/update/:id",
    name: "EditUser",
    component: () => import("../views/Users/EditUser.vue"),
    meta: {
      title: "Update User",
      breadcrumb: [
        { name: "Users", link: "/User/Index" },
        { name: "update User" }
      ],
      checkPrivilege: false,
    },
  },
  {
    path: "/AdvanceSetup/:moduleCode?",
    name: "AddUser",
    component: () => import("../views/AdvanceSetup/index.vue"),
    meta: {
      title: "Advance Setup",
      checkPrivilege: false,
      breadcrumb: [
        { name: "Advance Setup", link:"/AvanceSetup/Index" }
      ],
    },
  },
  {
    path: "/Priority/Index",
    name: "Priority",
    component: () => import("../views/Company/Priority/List.vue"),
    meta: {
      title: "Manage Priority",
      //breadcrumb: [{ name: "Priority" }],
    },
  },
  {
    path: "/Question/Index",
    name: "Question",
    component: () => import("../views/Company/Question/List.vue"),
    meta: {
      title: "Manage Question",
      //breadcrumb: [{ name: "Priority" }],
    },
  },
  {
    path: "/User/Dashboard",
    name: "Dashboard",
    component: () => import("../views/ShortcutDashboard/Dashboard.vue"),
    meta: { 
        title: 'Dashboard',            
    }
 },
 {
  path: "/Company/InitialSetup",
  name: "InitialSetup",
  component: () => import("../views/InitialSetup/InitialSetup.vue"),
  meta: { 
      title: 'Initial Setup',
      checkPrivilege: false,            
  }
},
// {
//   path: "/Company/InitialSetupOld",
//   name: "InitialSetup",
//   component: () => import("../views/InitialSetupOld/InitialSetup.vue"),
//   meta: { 
//       title: 'Initial Setup',
//       checkPrivilege: false,            
//   }
// },
{
  path: "/TimeTracking/ImportCategory",
  name: "ImportCategory",
  component: () => import("../views/InitialSetup/SilentApp/Modals/ImportCategory.vue"),
  meta: {
    title: "Import Category",
    breadcrumb: [{ name: "Import Category" }],
    checkPrivilege:false,
  }
},
{
  path: "/User/Home",
  name: "Dashboard",
  component: () => import("../views/ShortcutDashboard/Dashboard.vue"),
  meta: { 
      title: 'Home',            
      checkPrivilege: false,
  }
},{
  path: "/User/ResourceWorkLoad",
  name: "ResourceWorkLoad",
  component: () => import("../views/Users/WorkLoad.vue"),
  meta: {
    title: "Work Load",
    breadcrumb: [
      { name: "Users", link: "/User/Index" },
      { name: "Work Load" }
    ],
    checkPrivilege: false,
  },
},
{
  path: "/Client/Index",
  name: "ClientListing",
  component: () => import("../views/Client/ClientListing.vue"),
  meta: {
      title: 'Manage Clients',
      breadcrumb: [
          { name: "Client" }
      ],
      checkPrivilege: false,
  },
},
{
  path: "/Client/UpdateAccount/:id",
  name: "UpdateAccount",
  component: () => import("../views/Client/UpdateAccount.vue"),
  meta: {
      title: 'Update Account',
      
      breadcrumb: [
          {  name: "Client", link: "/Client/Index" },
          { name: "Update Account" }
      ],
      checkPrivilege: false,
  },
},
{
  path: "/Client/AddClient",
  name: "AddClient",
  component: () => import("../views/Client/AddClient.vue"),
  meta: {
      title: 'Add Client',
      breadcrumb: [
          {  name: "Client", link: "/Client/Index" },
          { name: "Add Client" }
      ],
      checkPrivilege: false,
  },
},
{
  path: "/Client/AssociateClient/:email/:id",
  name: "AssociateClient",
  component: () => import("../views/Client/AssociateClient.vue"),
  meta: {
      title: 'Add Client',
      breadcrumb: [
          {  name: "Client", link: "/Client/Index" },
          { name: "Add Client" }
      ],
      checkPrivilege: false,
  },
},
{
  path: "/Client/Update/:id/:parentId",
  name: "UpdateClient",
  component: () => import("../views/Client/UpdateClient.vue"),
  meta: {
      title: 'Update Client',
      
      breadcrumb: [
          {  name: "Client", link: "/Client/Index" },
          { name: "Update Client" }
      ],
      checkPrivilege: false,
  },
},
{
  path: "/Company/CheckList",
  name: "CheckList",
  component: () => import("../views/Company/CheckList/CheckList.vue"),
  meta: {
      title: 'CheckList List',
      breadcrumb: [
          {  name: "CheckList", link: "/Company/Index" }
      ],
      checkPrivilege: false,
  },
},
{
  path: "/Client/subscriptions",
  name: "AddClient",
  component: () => import("../views/Client/Subscription/Subscription.vue"),
  meta: {
      title: 'Subscription List',
      breadcrumb: [
          {  name: "Client", link: "/Client/Index" }
      ],
      checkPrivilege: false,
  },
},
{
  path: "/Client/payments",
  name: "Payment",
  component: () => import("../views/Client/Payments/payments.vue"),
  meta: {
      title: 'Payment List',
      breadcrumb: [
          {  name: "Client", link: "/Client/Index" }
      ],
      checkPrivilege: false,
  },
},
{
  path: "/Company/Team",
  name: "TeamList",
  component: () => import("../../src/views/Team/TeamList.vue"),
  meta: {
      title: 'Team List',
      breadcrumb: [
          {  name: "Project", link: "/Project/Index" }
      ],
      checkPrivilege: false,
  },
},
{
  path: "/ClientBill/Index",
  name: "ClientBillListing",
  component: () => import("../views/Client/ClientBillListing.vue"),
  meta: {
      title: 'Client Bill',
      breadcrumb: [
          { name: "Client Bill" }
      ],
      checkPrivilege: false,
  },
},
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
const DEFAULT_TITLE = "Home";
router.beforeResolve(async (to, from, next) => {
  document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
  document.getElementById("divProgress1").style.display = 'none';
  const ustore = useloggedUserInfo();
  let app = ustore.GetUserInfo;
  if(to.path.toLowerCase() != '/company/initialsetup')
  {
    document.getElementById("talygenfooter").style.display = 'block'; 
  }
  if (to.path == '/callback' || to.path == '/unauthorized' || to.path == '/logoutcallback' || to.path == '/Dashboard/Home') {
    if(to.path == '/Dashboard/Home' && app.UserType == "CL"){
      window.location.href = process.env.VUE_APP_DEFAULT_APP_URL + "/Client/Dashboard";
    } else {
      next()
    }
  }
  else if (to.path == '/Ticketing/Dashboard') {
      router.push({ path: '/Dashboard/Ticketing' });
  }
  // else if(to.path == '/User/Dashboard')
  // {
  //   next('/Company/InitialSetup');
  // }
  else if (to.path == '/User/Home') {
    if(app.UserType == "CL") {
      window.location.href = process.env.VUE_APP_DEFAULT_APP_URL + "/Client/Dashboard";
    }
    let url = `companyId=${app.CompanyId}`;    
    DataService.GetUserCompanyType(url).then((value) => { 
        const stringValue = String(value.data || ""); 
        if (stringValue != "" && stringValue.toLowerCase() === "silentapp") {
            next('/TimeTracking/DepartmentViewDasboard');
        } else {
          next('/Dashboard/Home');
        }
    })
  }
  else {
      if (app) {
          if(app.IsDefault){
            var isInitialSetup = false;
            let url = `type=initialstartup`;
            await DataService.GetUserSettingConfigByType(url).then((response) => {
              isInitialSetup = response.data;
          });
          if(!isInitialSetup){
              next();
            }
          }
          let isCheckPrivilege = true;
          if (to.meta.hasOwnProperty('checkPrivilege')) {
              isCheckPrivilege = to.meta.checkPrivilege;
          }

          if (isCheckPrivilege) {
              let controllerName = "", actionName = "";
              if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                  controllerName = to.meta.controllerName;
                  actionName = to.meta.actionName;
              }
              else {
                  let pathArray = to.path.split("/");
                  if (pathArray.length > 2) {
                      controllerName = pathArray[1];
                      actionName = pathArray[2];
                  } else {
                      controllerName = pathArray[0];
                      actionName = pathArray[1];
                  }
              }
              if (controllerName != "" && actionName != "") {
                  DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                      if (value.data)
                          next()
                      else
                          //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                          next('/unauthorized');
                  },
                      (error) => {
                          console.log(error);
                      }
                  );
              } else {
                  next()
              }
          } else {
              next();
          }
      }
      else {
          authService.authenticate(to.path).then(() => {
              console.log('authenticating a protected url:' + to.path);
              next();
          });
      }
  }
});
export default router;
